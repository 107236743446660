import React from 'react'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import { Adsense } from '@components/Adsense/Adsense'
import { Box, Label, Input, Textarea, Button, Message, Spinner, Text} from 'theme-ui'
import { Link } from 'gatsby';


const PageTarjetaMastercard = props => {
  

  return (
    <Layout {...props}>
      <Seo title='Tarjeta-Santander Mastercard' />
      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2292291997787849"
     crossOrigin="anonymous"></script>
      <Divider />
     <Stack>

     </Stack>
      <Adsense />

      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          header='¡Sacá tu Mastercard Santander! Gratis 💳🚀'
          subheader='Descubre los beneficios exclusivos y requisitos para solicitarla sin importar si estás en Veraz'
        />
      </Stack>
    
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
        <Link to="/prestamos-bbva">
          <Button style={{margin: '5px', width: '350px'}} variant="white">Préstamo de $650.000 💰 </Button>
        </Link>
        <Link to="/efectivo-si">
          <Button style={{margin: '5px', width: '350px'}} variant="white">Préstamo de $300.000 💸 </Button>
        </Link>
        <Link to="/credito-hipotecario">
          <Button style={{margin: '5px', width: '350px'}} variant="white">Sacá tu Crédito Hipotecario 💸🏡 </Button>
        </Link>
        <Link to="/prestamos-galicia">
          <Button style={{margin: '5px', width: '350px'}} variant="white">Préstamo de $80.000 💳 </Button>
        </Link>
      </div>
      <Adsense />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          subheader='
          Si estás en busca de una experiencia financiera que te brinde ahorros, beneficios y conveniencia, la Tarjeta Santander Mastercard es tu opción ideal. Descubre cómo esta tarjeta puede mejorar tu estilo de vida y brindarte ventajas exclusivas.
<br><br>  
1. ¡Sin Cargo de Mantenimiento por 6 Meses!<br>
Obtén tu Tarjeta Santander Mastercard y comienza a disfrutar de la libertad financiera sin preocuparte por cargos de mantenimiento durante los primeros 6 meses.
<br><br>
2. Programas de Afinidad: SuperClub+<br>
Premiamos tus compras con el programa SuperClub+. Cada transacción con tu Tarjeta Santander Mastercard Internacional suma puntos que podrás canjear por comodines de ahorro, premios, crédito en tarjeta y mucho más. Descubre todas las posibilidades en el catálogo de SuperClub+.'
        />
      </Stack>
      <Adsense />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          subheader='
          
          
Título: "Descubre los Beneficios Exclusivos de la Tarjeta Santander Mastercard en Argentina"
<br><br>
Si estás en busca de una experiencia financiera que te brinde ahorros, beneficios y conveniencia, la Tarjeta Santander Mastercard es tu opción ideal. Descubre cómo esta tarjeta puede mejorar tu estilo de vida y brindarte ventajas exclusivas.
<br><br>
1. ¡Sin Cargo de Mantenimiento por 6 Meses!<br>
Obtén tu Tarjeta Santander Mastercard y comienza a disfrutar de la libertad financiera sin preocuparte por cargos de mantenimiento durante los primeros 6 meses.
<br><br>
2. Programas de Afinidad: SuperClub+<br>
Premiamos tus compras con el programa SuperClub+. Cada transacción con tu Tarjeta Santander Mastercard Internacional suma puntos que podrás canjear por comodines de ahorro, premios, crédito en tarjeta y mucho más. Descubre todas las posibilidades en el catálogo de SuperClub+.
<br><br>
3. Ahorros Exclusivos en Más de 1.000 Marcas:<br>
Disfruta de descuentos exclusivos en compras, viajes, cines, espectáculos, gastronomía y más. Ejemplos incluyen hasta 30% de ahorro en McDonalds, PedidosYa con descuentos exclusivos, y un 30% de ahorro en Rex con cuotas sin interés.
<br><br>
4. Financiación a tu Medida con Plan V:<br>
Llama a Súper Línea y descubre cómo financiar tu Tarjeta Santander crédito en cuotas fijas en pesos con Plan V. Elige el plazo más conveniente para ti y disfruta de un manejo financiero más flexible.
          '
        />
      </Stack>
      <Adsense />
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
      <Link to="https://www.anses.blog/volvio-el-credito-hipotecario-con-una-oportunidad-historica-para-tener-tu-casa-propia/" style={{ textDecoration:'none', }}>
          <Text style={{margin: '5px', width: '100%', height: '100%',color: 'omegaDark' , fontWeight:'600'}}><span style={{fontWeight:'700'}}>
            También puede interesarte: </span>¡Volvío el Crédito Hipotecario!: Una Oportunidad Histórica para Acceder a la Vivienda Propia en Argentina. 🏡</Text>
        </Link>
      </div>
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          subheader='
          5. Servicios Exclusivos:
          <br><br>
Protección de compras
Centro de atención de denuncias
Tarjetas adicionales sin cargo
<br><br>
6. Requisitos de Acceso:
<br>
Ingresos mínimos mensuales de $260.000.<br>
Antigüedad laboral de 6 meses.<br>
Autónomos o monotributistas: DNI, CUIT/CUIL.<br>
Trabajadores en relación de dependencia: DNI, CUIT/CUIL, recibo de sueldo.<br>
¡Tarjeta Santander Mastercard Universitaria!<br><br>
Si eres universitario, obtén tu tarjeta GRATIS cumpliendo con estos requisitos:
<br><br>
Ser estudiante universitario.
Presentar Certificado de alumno regular o cuota paga de universidad según corresponda.
Tener entre 18 y 31 años de edad.
Ingresos mínimos mensuales: $15.000
Presentar el DNI.
<br><br>
Cómo Solicitar tu Tarjeta de Crédito Santander:<br>
Sigue estos simples pasos:<br>
<br><br>
Instala la App Santander para mayor comodidad.
Desde tu celular, ingresa al sitio tienda.santander.com.ar/productos y sigue los pasos.
Una vez solicitada, sigue el estado del envío en tu Online Banking.
Si ya eres cliente Santander, gestiona tu Tarjeta de Crédito Santander comunicándote con tu ejecutivo de cuenta, llamando a Superlínea al (011) 4345-2400 o al 0810-333-2400, o acercándote a una sucursal.
          '
        />
      </Stack>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
      <Link to="https://www.anses.blog/volvio-el-credito-hipotecario-con-una-oportunidad-historica-para-tener-tu-casa-propia/" style={{ textDecoration:'none', }}>
          <Text style={{margin: '5px', width: '100%', height: '100%',color: 'omegaDark' , fontWeight:'600'}}><span style={{fontWeight:'700'}}>
            También puede interesarte: </span>¡Volvío el Crédito Hipotecario!: Una Oportunidad Histórica para Acceder a la Vivienda Propia en Argentina. 🏡</Text>
        </Link>
      </div>
      <Adsense />

    </Layout>
  )
}

export default PageTarjetaMastercard
